class Menu {
    menu: any;

    constructor() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        $(window).resize(this.destroy.bind(this));
        $(window).resize(this.height.bind(this));
        this.height();
        $(window).scrollTop();
        $(window).scroll(this.yScroll.bind(this));

    }
    toggle(event){
        event.preventDefault();
        $('body').toggleClass('is-activeNav');
    }
    destroy() {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    }

    height() {
            this.menu = $('.c-outer-nav').outerHeight();
            $('body').css({"padding-top": `${this.menu}px`});
    }

    yScroll() {
         var scroll = $(window).scrollTop();
          //>=, not <=
         if (scroll >= 1) {
             //clearHeader, not clearheader - caps H
             if ($('.is-scrolled').length) return;
              $('body').addClass("is-scrolled");
         }else if (scroll <= 1){
               if ($('.is-scrolled').length) {
                 $('body').removeClass("is-scrolled");
               }
         }
    }

}
let menu = new Menu();
